/* CSS for the App Component */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@400;700&family=Hind+Vadodara&family=Oswald&display=swap');
@import url("https://use.typekit.net/pae4xel.css");


/* Variables */

:root {
    /* Font Family */
    --font-primary: 'Oswald', sans-serif;
    --font-secondary: 'Fira Sans Condensed', sans-serif;
    --font-tertiary: 'Hind Vadodara', sans-serif;

    /* Colors */
    --accent-color-vibrant: #ff6542;
    --accent-color-bold: #88498f;
    --accent-color: #779FA1;

    --background-color-black: #000000;
    --background-color-dark: #564154;
    --background-color-mid: #E0CBA8;

    --text-color-light: #ffffff;
    --text-color-med: #e0cba8;

    /* Spacing */
    --spacing-xs: 1rem;
    --spacing-sm: 2rem;
    --spacing-md: 4rem;
    
    font-size: 18px;
}


/* General Styles */
html {
    scroll-behavior: smooth;
    margin: 0;
}

body {
    font-family: var(--font-primary); 
    margin: 0; 
}

h1, h2, h3, h4, h5 {
    font-family: var(--font-secondary);
}  

main {
    padding-top: var(--spacing-sm);
    min-height:40vh;
}

p {
    line-height: 1.75rem;
}

/* Button Styles */
 
.btn {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 3px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0,0,0,0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 10px;

}

.btn:after {
  content: "";
  background-color: var(--accent-color-vibrant);
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 10px;
  left: 10px;
  transition: 0.25s;
  border-radius: 10px;
}

.btn:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .btn {
    padding: 13px 50px 13px;
  }
}

.btn-2:after {
    background-color: var(--accent-color);  
}



/* Header */

.navbar {
    min-height: 100px;
}

.navbar-collapse {
    flex-grow: 0;
}

.navbar-nav {
    align-items: flex-end;
    text-align: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: var(--spacing-sm);
}

.navbar-nav a {
    transition: all linear 0.25s;
}

.navbar-brand {
    flex-grow: 1;
}

.navbar p {
    margin-bottom: 0;
    font-family: var(--font-secondary);
    font-size: 2rem;
}

.navbar p > span {
    font-size: 1rem;
    font-family: var(--font-primary);
}

.navbar-toggler {
    border: transparent;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler-icon {
    background-image: none;
}

.fa-x {
    color: var(--accent-color-vibrant);
}

/* Home Page */
main#home {
    padding-top: var(--spacing-md);
    text-align: center;
}

.home-content h2 {
    font-size: 3rem;
}

.home-content h2 span {
    color: var(--accent-color-vibrant);
}

.home-content div {
    padding: var(--spacing-md) 0;
}

.home-content div > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.home-content div > div a:hover {
    color: #000000;
    font-weight: 700;
}

/* About Page */
main#about .row {
    padding: var(--spacing-sm) 0;
}

main#about div.headshot {
    transform: rotate(-2deg);
    background-color: var(--accent-color);
    border-radius: 30px;
    text-align: center;
    margin-right: var(--spacing-md);
    padding: var(--spacing-sm);
}

main#about div.headshot img {
    border-radius: 30px;
    border: 10px solid var(--accent-color)
}


/* Portfolio  Page */
main#portfolio {
    min-height: 70vh;
}

main#portfolio section {
    padding: var(--spacing-xs) 0;
}

/* Services Page */

main#services section, main#services h2 {
    padding: var(--spacing-xs) 0;
}

main#services section:nth-child(odd){
    margin-right: 5%;
}

main#services .row:first-of-type i {
    color: var(--accent-color-vibrant);
}

main#services .row:nth-of-type(2) i {
    color: var(--accent-color-bold);
}

main#services .row:nth-of-type(3) i {
    color: var(--accent-color);
}


/* Contact Page */
main#contact form {
    padding: var(--spacing-sm) 0;
}



/* Footer */
 footer { 
    background: var(--background-color-dark);
    color: var(--text-color-light);
    padding-bottom: var(--spacing-sm);
}

footer > div.container-fluid {
    position: relative;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding-top: var(--spacing-md);
}

footer .wave {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

footer .wave svg {
    position: relative;
    display: block;
    width: calc(101% + 1.3px);
    height: 63px;
    transform: rotateY(180deg);
    animation: 8s ease-in-out 0.5s infinite alternate breath; 
}

footer .wave .shape-fill {
    fill: #ffffff;
}

footer div .social a > i {
    color: var(--text-color-med);
    transition: all 0.25s linear;
}

footer div .social a > i:hover {
    color: var(--text-color-light);
    transform: scale(1.25);
   
}

/* Footer Animation */
@keyframes breath {
    0% {
        width: calc(101% + 1.3px);
    }
    5% {
        width: calc(101% + 1.3px);
    }
    95% {
        width: calc(300% + 1.3px);
    }
    100% {
        width: calc(300% + 1.3px);
    }
}